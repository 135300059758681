import React, { useState } from 'react'
import logo from '../img/busicon/logo.png'
import downlogo from '../img/busicon/down-logo.png'
import { FaBars } from 'react-icons/fa'

const Navbar = () => {
  const [showSmallScreenMenu, setShowSmallScreenMenu] = useState(false)

  const toggleSmallScreenMenu = () => {
    if (showSmallScreenMenu) {
      setShowSmallScreenMenu(false)
    } else {
      setShowSmallScreenMenu(true)
    }
  }

  return (
    <header className="tp-header-height">
      {/* tp-header-area-start */}
      <div
        id="header-sticky"
        className="tp-header-2__area header-sticky-bg-2 tp-header-2__transparent tp-header-2__plr z-index-5"
      >
        <div className="container-fluid g-0">
          <div className="row g-0 align-items-center">
            <div className="col-xl-2 col-lg-2 col-md-6 col-6">
              <div className="tp-header-2__logo">
                <a className="white-logo" href="/">
                  <img src={logo} alt="" style={{ height: '110px' }} />
                </a>
                <a className="black-logo" href="/">
                  <img
                    src={downlogo}
                    alt=""
                    style={{ marginBottom: '20px', height: '90px' }}
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 d-none d-lg-block">
              <div className="tp-header-2__main-menu text-center">
                <nav id="mobile-menu">
                  <ul>
                    <li>
                      <a href="/" style={{ textDecoration: 'none' }}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/about" style={{ textDecoration: 'none' }}>
                        About us
                      </a>
                    </li>
                    <li>
                      <a href="/" style={{ textDecoration: 'none' }}>
                        Services
                      </a>
                      <ul className="submenu">
                        <li>
                          <a href="/loans" style={{ textDecoration: 'none' }}>
                            Loans
                          </a>
                        </li>
                        <li>
                          <a
                            href="/insurance"
                            style={{ textDecoration: 'none' }}
                          >
                            Insurance
                          </a>
                        </li>
                        <li>
                          <a
                            href="/legalities"
                            style={{ textDecoration: 'none' }}
                          >
                            Legalities
                          </a>
                        </li>
                        <li>
                          <a
                            href="collaborations"
                            style={{ textDecoration: 'none' }}
                          >
                            Collaboration
                          </a>
                        </li>
                        <li>
                          <a
                            href="/business"
                            style={{ textDecoration: 'none' }}
                          >
                            Business Management
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/contact" style={{ textDecoration: 'none' }}>
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* tp-header-area-end */}

      {/* Small Screen Navbar */}
      <div
        className={`small-screen-navbar d-lg-none z-index-6 ${
          showSmallScreenMenu ? 'active' : ''
        }`}
      >
        <div
          className="small-screen-navbar-inner"
          style={{
            textAlign: 'right',
            paddingRight: '10px',
            position: 'fixed',
            right: '0',
          }}
        >
          <button onClick={toggleSmallScreenMenu}>
            <FaBars
              style={{
                color: 'orange',
                marginTop: '20px',
                marginRight: '20px',
                fontSize: '30px',
                zIndex: '10000',
                background: 'transparent',
              }}
            />
          </button>
          {showSmallScreenMenu && (
            <div
              className="small-screen-menu"
              style={{ backgroundColor: 'white', padding: '10px' }}
            >
              <nav>
                <ul
                  style={{
                    textAlign: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    listStyle: 'none',
                    marginRight: '60px',
                    marginTop: '30px',
                  }}
                >
                  <li style={{ marginBottom: '10px' }}>
                    <a href="/" style={{ textDecoration: 'none' }}>
                      Home
                    </a>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <a href="/about" style={{ textDecoration: 'none' }}>
                      About us
                    </a>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <a href="/business" style={{ textDecoration: 'none' }}>
                      Business Management
                    </a>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <a
                      href="/collaborations"
                      style={{ textDecoration: 'none' }}
                    >
                      Collaborations
                    </a>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <a href="/loans" style={{ textDecoration: 'none' }}>
                      Loans
                    </a>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <a href="/insurance" style={{ textDecoration: 'none' }}>
                      Insurance
                    </a>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <a href="/legalities" style={{ textDecoration: 'none' }}>
                      Legalities
                    </a>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <a href="/contact" style={{ textDecoration: 'none' }}>
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Navbar
