import React from 'react'
import Navbar from '../commons/Navbar'
import Footer from '../commons/Footer'
import legalitiesimage from '../img/lono-services/legalities.jpg'
import ServiceCarousel from './ServiceCarousel'
// Importing breadcrumb images
import breadcrumbshape1 from '../img/breadcrumb/breadcrumb-shape-1.png'
import breadcrumbshape2 from '../img/breadcrumb/breadcrumb-shape-2.png'
import breadcrumbsub1 from '../img/breadcrumb/breadcrumb-sub-1.png'

// Importing service images
import svbg from '../img/service/sv-bg.jpg'

const Legalities = () => {
  return (
    <div>
      <Navbar />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <main>
            {/* breadcrumb-area-start */}
            <div className="breadcrumb__area breadcrumb-height p-relative blue-bg-2">
              <div className="breadcrumb__shape-1">
                <img src={breadcrumbshape1} alt="" />
              </div>
              <div className="breadcrumb__shape-2">
                <img src={breadcrumbshape2} alt="" />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-7">
                    <div className="breadcrumb__content">
                      <h3 className="breadcrumb__title tp-char-animation">
                        Legalities
                      </h3>
                      <div className="breadcrumb__list tp-title-anim">
                        <span className="child-one">
                          <a
                            href="/"
                            style={{ textDecoration: 'none', color: 'white' }}
                          >
                            Home
                          </a>
                        </span>
                        <span className="dvdr">
                          <i className="fal fa-angle-right" />
                        </span>
                        <span className="child-one">
                          <a
                            href="/services"
                            style={{ textDecoration: 'none', color: 'white' }}
                          >
                            Services
                          </a>
                        </span>
                        <span className="dvdr">
                          <i className="fal fa-angle-right" />
                        </span>
                        <span>Legalities</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-5 col-lg-4 text-center text-md-end">
                    <div className="breadcrumb__img p-relative text-start z-index">
                      <img
                        className="z-index-3"
                        src="/img/legalitieshero.jpeg"
                        alt=""
                      />
                      <div
                        className="breadcrumb__sub-img wow tpfadeUp"
                        data-wow-duration=".9s"
                        data-wow-delay=".4s"
                      >
                        <img src={breadcrumbsub1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* breadcrumb-area-end */}
            {/* service-details-area-start */}
            <div className="sv-details-area sv-details-space pt-100 pb-100">
              <div className="container">
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="sv-details-widget">
                      <div className="sv-details-category mb-30">
                        <div className="sv-details-category-title">
                          <h4 className="sv-details-title-sm">
                            Service Category
                          </h4>
                        </div>
                        <div className="sv-details-category-list">
                          <ul>
                            <li>
                              <a
                                href="/loans"
                                style={{ textDecoration: 'none' }}
                              >
                                <span>Loans</span>
                                <i className="fal fa-angle-right" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="/insurance"
                                style={{ textDecoration: 'none' }}
                              >
                                <span>Insurance</span>
                                <i className="fal fa-angle-right" />
                              </a>
                            </li>
                            <li className="active">
                              <a
                                href="/legalities"
                                style={{ textDecoration: 'none' }}
                              >
                                <span>Legalities</span>
                                <i className="fal fa-angle-right" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="collaborations"
                                style={{ textDecoration: 'none' }}
                              >
                                <span>Collaboration</span>
                                <i className="fal fa-angle-right" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="/business"
                                style={{ textDecoration: 'none' }}
                              >
                                <span>Business Management</span>
                                <i className="fal fa-angle-right" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="tp-service__dashboard "
                        data-background={svbg}
                      >
                        <div className="tp-service__top-content">
                          <h3 className="tp-service__title-white">
                            Data Analysis <br /> Tools &amp; Methods
                          </h3>
                          <a
                            className="tp-btn-orange tp-btn-hover alt-color-white"
                            href="/contact"
                            style={{ textDecoration: 'none' }}
                          >
                            <span>Contact Us</span>
                            <b />
                          </a>
                        </div>
                        <div className="tp-service__dashdboard-sm-img">
                          <img
                            className="wow tpfadeRight"
                            data-wow-duration=".9s"
                            data-wow-delay=".3s"
                            src="/img/contactus.jpeg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="sv-details-wrapper">
                      <div className="sv-details-thumb mb-45">
                        <img className="w-100" src={legalitiesimage} alt="" />
                      </div>
                      <div className="sv-details-title-box mb-55">
                        <h4
                          className="sv-details-title"
                          style={{ textAlign: 'center', marginBottom: '30px' }}
                        >
                          Service Overview
                        </h4>
                        <p>
                          Customers must contact several professionals to obtain
                          the necessary documents and agreements, including
                          registration certificates, legal requirements, tax
                          filings, and compliances, in order to process each
                          loan. The organisation is coming up with a solution
                          where all these needs are processed in one place
                          because it got tough and time-consuming to process all
                          these requirements.
                        </p>
                      </div>
                      <div className="sv-details-text mb-35">
                        <h4
                          className="sv-details-title"
                          style={{ textAlign: 'center', marginBottom: '15px' }}
                        >
                          Documentation
                        </h4>
                      </div>
                      <ServiceCarousel services={documentation} />

                      <div className="sv-details-text mb-35">
                        <h4
                          className="sv-details-title"
                          style={{ textAlign: 'center', marginBottom: '15px' }}
                        >
                          Real Estate
                        </h4>
                      </div>
                      <ServiceCarousel services={RealEstate} />

                      <div className="sv-details-text mb-35">
                        <h4
                          className="sv-details-title"
                          style={{ textAlign: 'center', marginBottom: '15px' }}
                        >
                          Legal Registrations
                        </h4>
                      </div>
                      <ServiceCarousel services={LegalRegistrations} />

                      <div className="sv-details-text mb-35">
                        <h4
                          className="sv-details-title"
                          style={{ textAlign: 'center', marginBottom: '15px' }}
                        >
                          Licenses& registrations
                        </h4>
                      </div>
                      <ServiceCarousel services={LicensesAndRegistrations} />

                      <div className="sv-details-text mb-35">
                        <h4
                          className="sv-details-title"
                          style={{ textAlign: 'center', marginBottom: '15px' }}
                        >
                          Tax & compliance
                        </h4>
                      </div>
                      <ServiceCarousel services={TaxAndCompilance} />

                      <div className="sv-details-text-2">
                        <h4 className="sv-details-text-title">The Challange</h4>
                        <p>
                          Streamlining the cumbersome process of gathering
                          various documents and agreements for loans by
                          centralizing them into one accessible platform.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* service-details-area-end */}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  )
}
export default Legalities

const documentation = [
  {
    name: 'Service level agreement',
    img: '/img/subservices/servicelevelagreement.jpeg',
  },
  {
    name: 'Franchise Agreement',
    img: '/img/subservices/franchiseagreement.jpeg',
  },
  {
    name: 'Shareholders Agreement',
    img: '/img/subservices/shareholdersagreement.jpeg',
  },
  {
    name: 'Joint venture agreement',
    img: '/img/subservices/jointventureagreement.jpeg',
  },
  {
    name: 'Founders agreement',
    img: '/img/subservices/foundersagreement.jpeg',
  },
  {
    name: 'Vendor & consultancy agreement',
    img: '/img/subservices/vendoragreement.jpeg',
  },
  {
    name: 'Scope of work agreement',
    img: '/img/subservices/scopeofwork.jpeg',
  },
  {
    name: 'Trade license agreement',
    img: '/img/subservices/tradelicense.jpeg',
  },
  {
    name: 'Finance agreement',
    img: '/img/subservices/finance agreement.jpeg',
  },
]

const RealEstate = [
  {
    name: 'Rental agreement',
    img: '/img/subservices/rentalagreement.jpeg',
  },
  {
    name: 'Sale deed',
    img: '/img/subservices/saledeed.jpeg',
  },
  {
    name: 'Gift deed',
    img: '/img/subservices/giftdeed.jpeg',
  },
  {
    name: 'Employment agreement',
    img: '/img/subservices/emplymentagreement.jpeg',
  },
  {
    name: 'Legal notice',
    img: '/img/subservices/legalnotice.jpeg',
  },
]

const LegalRegistrations = [
  {
    name: 'Business registration',
    img: '/img/subservices/businessregistration.jpeg',
  },
  {
    name: 'Private limited company',
    img: '/img/subservices/privatelimitedcompany.jpeg',
  },
  {
    name: 'Limited Liability partnership',
    img: '/img/subservices/limitedliabilitypartnership.jpeg',
  },
  {
    name: 'One person company',
    img: '/img/subservices/onepersoncompany.jpeg',
  },
  {
    name: 'Sole proprietorship',
    img: '/img/subservices/soloproprietership.jpeg',
  },
  {
    name: 'Nidhi company',
    img: '/img/subservices/nidhicompany.jpeg',
  },
  {
    name: 'Producer company',
    img: '/img/subservices/producercompany.jpeg',
  },
  {
    name: 'Partnership firm',
    img: '/img/subservices/partnershipfirm.jpeg',
  },
  {
    name: 'Startup India registration',
    img: '/img/subservices/startupindiaregistration.jpeg',
  },
]

const LicensesAndRegistrations = [
  {
    name: 'Digital signature certificate',
    img: '/img/subservices/digitalsignaturecertificate.jpeg',
  },
  {
    name: 'Udyam registration',
    img: 'img/subservices/udyamregistration.jpeg',
  },
  {
    name: 'MSME registration',
    img: '/img/subservices/msmeregistration.jpeg',
  },
  {
    name: 'ISO certification',
    img: '/img/subservices/isocertification.jpeg',
  },
  {
    name: 'FSSAI(food License)',
    img: '/img/subservices/fssai.jpeg',
  },
  {
    name: 'IEC(import/export code)',
    img: '/img/subservices/IEC.jpeg',
  },
]

const TaxAndCompilance = [
  {
    name: 'GST registration',
    img: '/img/subservices/gstregistration.jpeg',
  },
  {
    name: 'GST filing',
    img: '/img/subservices/GSTfiling.jpeg',
  },
  {
    name: 'Tax filing',
    img: '/img/subservices/taxfiling.jpeg',
  },
  {
    name: 'Provident fund (PF) registration',
    img: '/img/subservices/pfregistration.jpeg',
  },
  {
    name: 'ESI Registration',
    img: '/img/subservices/esiregistration.jpeg',
  },
  {
    name: 'Shops & established license',
    img: '/img/subservices/shopsandestablishment.jpeg',
  },
  {
    name: 'Professional tax registration',
    img: '/img/subservices/professionaltax.jpeg',
  },
  {
    name: 'TDS return filing',
    img: '/img/subservices/tdsreturnfiling.jpeg',
  },
  {
    name: 'Accounting’s and book keeping',
    img: '/img/subservices/accountingandbookkeeping.jpeg',
  },
  {
    name: 'Trade mark registration',
    img: '/img/subservices/trademarkregistration.jpeg',
  },
  {
    name: 'Patent registration',
    img: '/img/subservices/patentregistration.jpeg',
  },
  {
    name: 'Property registrations',
    img: '/img/subservices/propertyregistration.jpeg',
  },
]
