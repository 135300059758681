import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from '../src/home/Home.js'
import './App.css'
// import Services from './Services.js'
import Contact from './Contact.js'
import Finance from './services/Finance.js'
import Legalities from './services/Legalities.js'
import Collaborations from './services/Collaborations.js'
import Business from './services/Business.js'
import Insurance from './services/Insurance.js'
import About from './About.js'
// import Carousel from './commons/Carousel.js'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/loans" element={<Finance />} />
        <Route path="/legalities" element={<Legalities />} />
        <Route path="/collaborations" element={<Collaborations />} />
        <Route path="/business" element={<Business />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
