import React from 'react'
import Slider from 'react-slick'
import '../commons/Carousel.css'
import ServiceCard from './ServiceCard'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onClick={onClick}
    >
      NEXT
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    >
      BACK
    </div>
  )
}

const ServiceCarousel = ({ services }) => {
  const slider = React.useRef(null)

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <div style={{ marginBottom: '100px' }}>
      <div
        style={{
          margin: '10px 0 40px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button
          onClick={() => slider?.current?.slickPrev()}
          style={{ backgroundColor: 'transparent' }}
        >
          {' '}
          <FaArrowLeft /> Prev{' '}
        </button>
        <button
          style={{ marginLeft: 20, backgroundColor: 'transparent' }}
          onClick={() => slider?.current?.slickNext()}
        >
          Next <FaArrowRight />
        </button>
      </div>

      <Slider ref={slider} {...settings}>
        {services?.map((item, index) => {
          return <ServiceCard item={item} key={index} />
        })}
      </Slider>
    </div>
  )
}

export default ServiceCarousel
