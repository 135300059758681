import React from 'react'
import Navbar from './commons/Navbar.js'
import Footer from './commons/Footer.js'

//banks
import hdfc from './img/payment-logo/hdfc.png'
import sbi from './img/payment-logo/sbi.png'
import bob from './img/payment-logo/Bank-of-Baroda.png'
import icici from './img/payment-logo/icici.png'
import axis from './img/payment-logo/AXISBANK.BO.png'
import bajaj from './img/payment-logo/bajaj.png'
import tata from './img/payment-logo/tatacapital.png'
import pnb from './img/payment-logo/pnb.png'
import union from './img/payment-logo/unionbank.png'

//about images
import about1 from './img/about/about-1.jpg'
import about2 from './img/about/about-2.jpg'
import about3 from './img/about/about-3.jpg'
import about5 from './img/about/about-5.png'
import aboutbg from './img/about/about-bg-shape.png'

const About = () => {
  return (
    <div>
      {/* tp-offcanvus-area-start */}

      <div id="smooth-wrapper">
        <div id="smooth-content">
          <Navbar />
          <main>
            {/* tp-breadcrumb-area-start */}
            <div className="about-banner-area p-relative">
              <div className="about-banner p-relative z-index fix">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="about-banner-content z-index-5">
                        <h4
                          className="about-banner-title"
                          data-parallax='{"y": 1000, "smoothness": 10}'
                        >
                          <span>About</span> <br />
                          <span>Lono</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tp-breadcrumb-area-end */}

            {/*ab-brand-area-start */}
            <div className="ab-brand-area pt-90">
              <div className="container">
                <div className="ab-brand-border-bottom pb-90">
                  <div className="row">
                    <div className="col-12">
                      <div className="ab-brand-section-box text-center mb-50">
                        <h4 className="ab-brand-title">
                          Trusted by Hundreds of Banks
                        </h4>
                        <p>More than 100+ teams are using Lono</p>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 justify-content-center">
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay=".2s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={hdfc}
                              style={{ height: '73px', width: '150px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay=".4s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={sbi}
                              style={{ height: '73px', width: '150px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay=".6s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={bob}
                              style={{ height: '73px', width: '150px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay=".8s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={icici}
                              style={{ height: '73px', width: '150px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay=".9s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={union}
                              style={{ height: '73px', width: '150px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay="1s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={axis}
                              style={{ height: '73px', width: '150px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay="1.1s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={tata}
                              style={{ height: '80px', width: '160px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay="1.2s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={bajaj}
                              style={{ height: '80px', width: '160px' }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="col wow tpfadeUp"
                          data-wow-duration=".9s"
                          data-wow-delay="1.3s"
                        >
                          <div className="ab-brand-item mb-25">
                            <img
                              src={pnb}
                              style={{ height: '73px', width: '150px' }}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*ab-brand-area-end */}
            {/*ab-company-area-start */}
            <div className="ab-company-area pt-105 pb-100">
              <div className="container">
                <div className="row ab-company-section-space">
                  <div className="col-xl-6">
                    <div className="ab-company-section-box">
                      <h4 className="inner-section-subtitle">
                        ABOUT THE COMPANY
                      </h4>
                      <h3 className="tp-section-title">
                        Lono is Made <br />
                        <p
                          className="tp-section-title"
                          style={{
                            fontSize: '30px',
                            letterSpacing: '1px',
                            lineHeight: '1.3',
                          }}
                        >
                          For Secure, Streamlined Lending Solutions.
                        </p>
                      </h3>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 wow tpfadeRight"
                    data-wow-duration=".9s"
                    data-wow-delay=".5s"
                  >
                    <div className="ab-company-right">
                      <div className="ab-company-section-text">
                        <p className="pb-10">
                          Lono is a collaborative small business funding
                          platform to address the pain point of business loan
                          rejections due to various reasons , lack of knowledge
                          or process and lack of finance.
                        </p>
                        <p className="pb-10">
                          Lono would bring together banks , non-banking
                          financial companies (NBFCs) , private banks and other
                          financial institutions to support MSME (Micro , Small
                          and Medium Enterprises) businesses in a more
                          streamlined and efficient manner.
                        </p>
                        <p className="pb-10">
                          Lono serves as a centralized hub where entrepreneurs
                          can find resources , guidance and access to financing
                          options tailored to their specific needs. We provide
                          educational resources to improve business knowledge
                          and facilitate understanding of the processes involved
                          in establishing a successful business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row align-items-center"
                  style={{ alignItems: 'center' }}
                >
                  <div className="col-xl-4">
                    <div className="ab-company-video"></div>
                  </div>
                  <div className="col-xl-8">
                    <div className="row">
                      <div className="col-md-4 col-sm-4 mb-40">
                        <div className="ab-company-fun-fact-wrap d-flex justify-content-start">
                          <div className="ab-company-fun-fact">
                            <span>USED BY</span>
                            <h4>
                              200<em>+</em>
                            </h4>
                            <p>Users</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 mb-40">
                        <div className="ab-company-fun-fact-wrap ab-company-border-none d-flex justify-content-md-center justify-content-left">
                          <div className="ab-company-fun-fact">
                            <span>IN</span>
                            <h4>
                              20<em>+</em>
                            </h4>
                            <p>Banks using</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*ab-company-area-end */}
            {/* tp-about-area-start */}
            <div className="tp-about__area tp-about__pt-pb pb-160">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-xl-6 col-lg-6 wow tpfadeLeft"
                    data-wow-duration=".9s"
                    data-wow-delay=".2s"
                  >
                    <div className="tp-about__img-wrapper text-center text-lg-end p-relative">
                      <div className="tp-about__bg-shape">
                        <img src={aboutbg} alt="" />
                      </div>
                      <div className="tp-about__main-img z-index">
                        <img src={about2} alt="" />
                      </div>
                      <div className="tp-about__sub-img-1 d-none d-sm-block z-index-3">
                        <img src={about1} alt="" />
                      </div>
                      <div className="tp-about__sub-img-2 d-none d-sm-block">
                        <img src={about3} alt="" />
                      </div>
                      <div className="tp-about__sub-img-3 d-none d-sm-block z-index-3">
                        <img src={about5} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 wow tpfadeRight"
                    data-wow-duration=".9s"
                    data-wow-delay=".6s"
                  >
                    <div className="tp-about__right">
                      <div className="tp-about__section-box">
                        <h4 className="tp-section-subtitle">
                          OVER 15K+ CLIENT
                        </h4>
                        <h3
                          className="tp-section-title mb-15"
                          style={{ fontSize: '30px' }}
                        >
                          Lono: Empowering Financial Access
                        </h3>
                        <p>Unlocking Potential. Empowering Futures.</p>
                      </div>
                      <div className="tp-about__list">
                        <ul>
                          <li>
                            <i className="fal fa-check" />
                            Fast & secured loan approval banking technology
                            company .
                          </li>
                          <li>
                            <i className="fal fa-check" />
                            Increase rural banking facilities and employment.
                          </li>
                          <li>
                            <i className="fal fa-check" />
                            every citizen of India above 18 years age should
                            have easy access to start their business with zero
                            money.
                          </li>
                          <li>
                            <i className="fal fa-check" />
                            people protect their health & education from
                            childhood ,likewise we work to protect the financial
                            life of every citizen in India .
                          </li>
                        </ul>
                      </div>
                      <div className="tp-about__btn">
                        <a
                          className="tp-btn tp-btn-inner tp-btn-hover alt-color-black"
                          href="/about"
                        >
                          <span>About Us</span>
                          <b />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tp-about-area-end */}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default About
