import React from 'react'
import Slider from 'react-slick'
import './Carousel.css'
import BasicCard from './Card'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onClick={onClick}
    >
      NEXT
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    >
      BACK
    </div>
  )
}

const Carousel = () => {
  const slider = React.useRef(null)

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <div>
      <div
        style={{
          margin: '0 0 40px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button
          onClick={() => slider?.current?.slickPrev()}
          style={{ backgroundColor: 'transparent' }}
        >
          <FaArrowLeft style={{ marginRight: 5 }} /> Prev
        </button>
        <button
          style={{ marginLeft: 20, backgroundColor: 'transparent' }}
          onClick={() => slider?.current?.slickNext()}
        >
          Next <FaArrowRight style={{ marginLeft: 5 }} />
        </button>
      </div>

      <Slider ref={slider} {...settings}>
        {products?.map((item, index) => {
          return <BasicCard item={item} key={index} />
        })}
      </Slider>
    </div>
  )
}

const products = [
  {
    name: 'Business',
    description: "Now it's easy to start your business and succeed.",
    image: '/img/business.jpg',
    lnk: '/business',
  },
  {
    name: 'Collaborations',
    description: `Now save money and time for your secured collaborations.`,
    image: '/img/collaboration.jpg',
    lnk: '/collaborations',
  },
  {
    name: 'Loans',
    description: 'Make your every financial need easy and secure.',
    image: '/img/finance.jpg',
    lnk: '/loans',
  },
  {
    name: 'Insurance',
    description: 'We protect your insurance from fraud.',
    image: '/img/insurance.jpg',
    lnk: '/insurance',
  },
  {
    name: 'Legalities',
    description: `We keep your legalities to support your financial needs and benefits.`,
    image: '/img/legalities.jpg',
    lnk: '/legalities',
  },
]

export default Carousel
