import React from 'react'
import Slider from 'react-slick'
import './homecard.css'
import Card1 from './Card1'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onClick={onClick}
    >
      NEXT
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    >
      BACK
    </div>
  )
}

const Homecard = () => {
  const slider = React.useRef(null)

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <div>
      <div
        style={{
          margin: '0 0 40px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button
          onClick={() => slider?.current?.slickPrev()}
          style={{ backgroundColor: 'transparent' }}
        >
          <FaArrowLeft style={{ marginRight: 5 }} /> Prev
        </button>
        <button
          style={{ marginLeft: 20, backgroundColor: 'transparent' }}
          onClick={() => slider?.current?.slickNext()}
        >
          Next <FaArrowRight style={{ marginLeft: 5 }} />
        </button>
      </div>

      <Slider ref={slider} {...settings}>
        {products?.map((item, index) => {
          return <Card1 item={item} key={index} />
        })}
      </Slider>
    </div>
  )
}

const products = [
  {
    name: 'Fast and Secure lending',
    description1: 'One-day loan approval guaranteed.',
    description2: 'Lowest interest rates and fees available.',
    description3: 'Various professional services in one place.',
    description4: 'Connecting lenders to customers efficiently.',
    description5: 'Save money and time on loans.',
    image: '/img/card/fast&secure.jpg',
  },
  {
    name: 'Rural Banking',
    description1: 'Nearest banking service centers in villages.',
    description2: 'Easy bank account opening services .',
    description3: 'Faster money transactions and services.',
    description4: 'Multiple banks in one location.',
    description5: 'Save money by avoiding city transportation.',
    image: '/img/card/ruralbanking.jpg',
  },
  {
    name: 'Protect Your Family Finance',
    description1: 'Save children from micro lending issues.',
    description2: "Connect to children's and wife's accounts.",
    description3: 'Lowest interest rates on family accounts.',
    description4: 'Easily start and succeed in business.',
    image: '/img/card/protectfamily.jpg',
  },
  {
    name: 'Start your business from zero',
    description1: 'Start your business with zero money.',
    description2: 'Lowest interest rates on business loans.',
    description3: 'Streamlined business plan process available.',
    description4: 'Save time and money in execution.',
    image: '/img/card/startbusiness.jpg',
  },
]

export default Homecard
