export const team = [
  {
    name: 'T.Vikas',
    role: 'Founder and CEO',
  },
  {
    name: 'R.Manesh',
    role: 'CEO Team',
  },
  {
    name: 'Pavan kumar',
    role: 'Chief Technical Officer',
  },
  {
    name: 'M.Lokesh',
    role: 'HR team',
  },
]

export const bankdata = [
  {
    name: 'Union Bank',
    img: 'unionbank',
    lnk: 'https://www.unionbankofindia.co.in/english/home.aspx',
    tagline:
      'Union Bank of India offers online digital services for personal and corporate banking, such as loans, deposits, mutual funds, insurance and more.',
  },
  {
    name: 'SBI',
    img: 'sbi',
    lnk: 'https://sbi.co.in/',
    tagline:
      'SBI offers a range of banking products and services, such as loans, accounts, cards, investment, deposits, net banking and more.',
  },
  {
    name: 'Axis',
    img: 'AXISBANK.BO',
    lnk: 'https://www.axisbank.com/',
    tagline:
      'Axis Bank is a leading private sector bank in India with online banking options for personal and corporate customers. ',
  },
  {
    name: 'ICICI',
    img: 'icici',
    lnk: 'https://www.icicibank.com/',
    tagline:
      'ICICI Bank is a leading private sector bank in India that offers personal, business, corporate and NRI banking solutions. ',
  },
  {
    name: 'HDFC',
    img: 'hdfc',
    lnk: 'https://www.hdfcbank.com/',
    tagline: `HDFC Bank is India's leading private sector bank, offering a range of banking products and services like accounts, deposits, cards, loans, investments and insurance. `,
  },
  {
    name: 'Kotak Mahindra Bank',
    img: 'kotak',
    lnk: 'https://www.kotak.com/en/home.html',
    tagline:
      'Kotak Mahindra Bank offers high interest rate savings account, low interest rate personal loan and credit cards with attractive offers. ',
  },
  {
    name: 'Central Bank',
    img: 'centralbank',
    lnk: 'https://centralbankofindia.co.in/en',
    tagline:
      'Central Bank of India offers various banking products and services for personal and corporate customers, such as savings accounts, loans, insurance, demat and trading accounts. ',
  },
  {
    name: 'Canara Bank',
    img: 'canara bank',
    lnk: 'https://canarabank.com/',
    tagline:
      'Canara Bank offers a wide range of banking services like a savings account, FD, debit card, loan, and many more.',
  },
  {
    name: 'Fino Bank',
    img: 'fino',
    lnk: 'https://www.finobank.com/',
    tagline:
      'Fino Payments Bank is the Digital Banking Partner for millions of hardworking Indians.',
  },
  {
    name: 'Bank of Baroda',
    img: 'Bank-of-Baroda',
    lnk: 'https://www.bankofbaroda.in/',
    tagline: `Bank of Baroda is India's International Bank that offers a range of banking products and services online and offline.`,
  },
  {
    name: 'Bank of Maharashtra',
    img: 'bank of maharashtra',
    lnk: 'https://bankofmaharashtra.in/',
    tagline:
      'Bank of Maharashtra offers deposits, loans, digital products and government schemes for personal, corporate, MSME and NRI customers.',
  },
  {
    name: 'UCO',
    img: 'uco',
    lnk: 'https://ucoebanking.in/',
    tagline: `UCO Bank - India's leading public sector banks, offering a wide range of financial services and products.`,
  },
  {
    name: 'IDBI',
    img: 'idbi',
    lnk: 'https://www.idbibank.in/',
    tagline:
      'IDBI Bank offers a wide range of products from savings and current bank account to loans for retail and msme customers or agri loans to farmers.',
  },
  {
    name: 'Punjab National Bank',
    img: 'punjab national',
    lnk: 'https://www.pnbindia.in/',
    tagline: `Punjab National Bank is an Ideal destination for all Banking need! PNB offer a wide range of personal banking services including loans, credit cards, savings account and fixed deposits`,
  },
  {
    name: 'RBL',
    img: 'rbl',
    lnk: 'https://www.rblbank.com/',
    tagline:
      'RBL Bank offers online banking, credit cards, personal loans, fixed deposits, savings account and insurance for personal and corporate customers.',
  },
  {
    name: 'Gayatri Bank',
    img: 'gayathri',
    lnk: 'https://www.gayatribank.in/',
    tagline:
      'Gayatri bank is a high-quality, customer-centric, service-driven, Co-Operative Bank in India.',
  },
]

export const plans = [
  {
    lnk: 'benefits',
  },
  {
    lnk: 'businessplan',
  },
  {
    lnk: 'companyaim',
  },
  {
    lnk: 'customervalidation',
  },
  {
    lnk: 'projectmanagement',
  },
  {
    lnk: 'subject',
  },
]
