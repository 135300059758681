import Card from 'react-bootstrap/Card'

function Card1({ item }) {
  return (
    <Card
      style={{
        width: 400,
        height: 500,
        background: 'transparent',
        color: 'white',
        padding: '10px',
        marginBottom: '20px',
        margin: 'auto',
      }}
    >
      <Card.Img
        variant="top"
        style={{ height: '300px', width: '300px', paddingLeft: '30px' }}
        src={item.image}
      />
      <Card.Body>
        <Card.Title style={{ color: 'orange', textAlign: 'center' }}>
          {item.name}
        </Card.Title>
        <Card.Text style={{ color: 'white' }}>{item.description1}</Card.Text>
        <Card.Text style={{ color: 'white' }}>{item.description2}</Card.Text>
        <Card.Text style={{ color: 'white' }}>{item.description3}</Card.Text>
        <Card.Text style={{ color: 'white' }}>{item.description4}</Card.Text>
        <Card.Text style={{ color: 'white' }}>{item.description5}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Card1
