import Card from "react-bootstrap/Card";

function BasicCard({ item }) {
  return (
    <Card
      style={{
        width: 300,
        height: 400,
        background: "transparent",
        color: "white",
        padding: "10px",
        marginBottom: "20px",
        margin: "auto"
      }}
    >
      <a href={item.lnk} style={{textDecoration:"none"}}>
        <Card.Img variant="top" style={{ height: '220px' }} src={item.image} />
        <Card.Body>
          <Card.Title style={{color:"orange"}}>{item.name}</Card.Title>
          <Card.Text style={{color:"white"}}>
            {item.description}
          </Card.Text>
        </Card.Body>
      </a>
    </Card>
  );
}

export default BasicCard;
