import Card from "react-bootstrap/Card";

function ServiceCard({ item }) {
  return (
    <Card
      style={{
        width: 210,
        height: 280,
        background: "transparent",
        color: "white",
        padding: "10px",
        marginBottom: "20px"
      }}
    >
      <a href={item.lnk} style={{textDecoration:"none"}}>
        <Card.Img variant="top" style={{ height: '180px' }} src={item.img} />
        <Card.Body>
          <Card.Title style={{color:"orange",fontSize:"18px"}}>{item.name}</Card.Title>
        </Card.Body>
      </a>
    </Card>
  );
}

export default ServiceCard;
